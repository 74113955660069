export enum Branch {
	Cakovice = "Cakovice",
	Central = "Central",
	Petriny = "Petriny",
}

export const branchNameMap: Record<Branch, string> = {
	[Branch.Cakovice]: "Čakovice",
	[Branch.Central]: "Centrála",
	[Branch.Petriny]: "Petřiny",
};

export const branchLongNameMap: Record<Branch, string> = {
	[Branch.Cakovice]: "Sladká dílna - Čakovice",
	[Branch.Central]: "Sladká dílna - centrála",
	[Branch.Petriny]: "Sladká dílna - Petřiny",
};
